import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "margin-subpages" }
const _hoisted_2 = { class: "icons-table" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "title", "alt"]
const _hoisted_6 = ["onClick", "title"]
const _hoisted_7 = { class: "icons-table analysis" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["src", "title", "alt"]
const _hoisted_11 = ["onClick", "title"]
const _hoisted_12 = { class: "icons-table analysis" }
const _hoisted_13 = ["href"]
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["src", "title", "alt"]
const _hoisted_16 = ["onClick", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fs-20 fw-700" }, "Click icon for copy image tag to clipboard", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fs-18 mt-32" }, "Icons (.png)", -1)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.iconList, (value) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'icon_' + value,
          class: "icon-body my-12 mr-12"
        }, [
          _createElementVNode("a", {
            href: value,
            class: "center",
            style: {"text-decoration":"none"},
            target: "_blank",
            rel: "noopener noreferrer",
            ref_for: true,
            ref: "mylink"
          }, [
            _createElementVNode("div", {
              class: "m-auto",
              onClick: _withModifiers(($event: any) => (_ctx.copyTag(value)), ["prevent"])
            }, [
              _createElementVNode("img", {
                class: "icon-image",
                src: _ctx.imagePath(value),
                title: value,
                alt: value
              }, null, 8, _hoisted_5)
            ], 8, _hoisted_4),
            _createElementVNode("div", {
              onClick: _withModifiers(($event: any) => (_ctx.copyName(value)), ["prevent"]),
              class: "fc-1B1C1E opa-07 fs-10 mt-4 ellipsis",
              style: {"max-width":"100px"},
              title: value
            }, _toDisplayString(value), 9, _hoisted_6)
          ], 8, _hoisted_3)
        ]))
      }), 128))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "fs-18 mt-32" }, "Icons (.svg)", -1)),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.iconSVGList, (value) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'icon_' + value,
          class: "icon-body my-12 mr-12"
        }, [
          _createElementVNode("a", {
            href: value,
            class: "center",
            style: {"text-decoration":"none"},
            target: "_blank",
            rel: "noopener noreferrer",
            ref_for: true,
            ref: "mylink"
          }, [
            _createElementVNode("div", {
              class: "m-auto",
              onClick: _withModifiers(($event: any) => (_ctx.copySVGTag(value)), ["prevent"])
            }, [
              _createElementVNode("img", {
                class: "icon-image",
                src: _ctx.imageSVGPath(value),
                title: value,
                alt: value
              }, null, 8, _hoisted_10)
            ], 8, _hoisted_9),
            _createElementVNode("div", {
              onClick: _withModifiers(($event: any) => (_ctx.copyName(value)), ["prevent"]),
              class: "fc-1B1C1E opa-07 fs-10 mt-4 ellipsis",
              style: {"max-width":"100px"},
              title: value
            }, _toDisplayString(value), 9, _hoisted_11)
          ], 8, _hoisted_8)
        ]))
      }), 128))
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "fs-18 mt-32" }, "Analysis Section", -1)),
    _createElementVNode("div", _hoisted_12, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.analysisSectionIcons, (value) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'icon_' + value,
          class: "icon-body my-12 mr-12"
        }, [
          _createElementVNode("a", {
            href: value,
            class: "center",
            style: {"text-decoration":"none"},
            target: "_blank",
            rel: "noopener noreferrer",
            ref_for: true,
            ref: "mylink"
          }, [
            _createElementVNode("div", {
              class: "m-auto",
              onClick: _withModifiers(($event: any) => (_ctx.copyTagAnalysisSection(value)), ["prevent"])
            }, [
              _createElementVNode("img", {
                class: "icon-image",
                src: _ctx.imagePathAnalysisSection(value),
                title: value,
                alt: value
              }, null, 8, _hoisted_15)
            ], 8, _hoisted_14),
            _createElementVNode("div", {
              onClick: _withModifiers(($event: any) => (_ctx.copyName(value)), ["prevent"]),
              class: "fc-1B1C1E opa-07 fs-10 mt-4 ellipsis",
              style: {"max-width":"100px"},
              title: value
            }, _toDisplayString(value), 9, _hoisted_16)
          ], 8, _hoisted_13)
        ]))
      }), 128))
    ])
  ]))
}