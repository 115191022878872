import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/icons/language_black_24dp@2x.png'


const _hoisted_1 = { class: "margin-subpages" }
const _hoisted_2 = { class: "opa-05" }
const _hoisted_3 = { class: "select-with-icon mt-16" }
const _hoisted_4 = { class: "p-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _cache[4] || (_cache[4] = _createElementVNode("div", { class: "title" }, "Chang Lang", -1)),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_2, "(Now - " + _toDisplayString(_ctx.lang) + ") | " + _toDisplayString(_ctx.$t("test")), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_select, {
            modelValue: _ctx.lang,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.lang) = $event)),
            class: "select-login e-input-52",
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_option, {
                label: "English",
                value: "en"
              }),
              _createVNode(_component_el_option, {
                label: "ภาษาไทย",
                value: "th"
              }),
              _createVNode(_component_el_option, {
                label: "FALSE",
                value: "false"
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "icon-left flex-mid" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "icon-20",
              alt: "icons_language_black_24dp@2x"
            })
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_el_button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push('/'))),
            type: "warning",
            link: ""
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Home")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}